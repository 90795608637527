@media (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}
.main-header {
  inset: 0 0 auto;
  z-index: 99;
}
.header-fixed .main-header {
  position: fixed;
}
@media (max-width: 1199px) {
  .main-header {
    padding: 12px 25px;
  }
  .main-header .container,
  .main-header .container-fluid,
  .main-header .container-xxl,
  .main-header .container-xl,
  .main-header .container-lg,
  .main-header .container-md,
  .main-header .container-sm {
    --bs-gutter-x: 0;
  }
}
@media (max-width: 767px) {
  .main-header .main-header .main-logo img {
    width: 80px;
    height: auto;
  }
}
.main-header .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='14' viewBox='0 0 20 14' fill='none'%3E%3Crect width='20' height='2' rx='1' fill='white'/%3E%3Crect y='6' width='20' height='2' rx='1' fill='white'/%3E%3Crect y='12' width='20' height='2' rx='1' fill='white'/%3E%3C/svg%3E");
  --bs-navbar-toggler-padding-y: 13px;
  --bs-navbar-toggler-padding-x: 12px;
  --bs-navbar-toggler-border-radius: 12px;
}
.main-header .navbar .navbar-toggler {
  background-color: #1f2126;
  color: #fff;
  width: 44px;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.main-header .navbar .navbar-toggler .navbar-toggler-icon {
  width: 20px;
  height: 14px;
  display: block;
}
@media (max-width: 1199px) {
  .main-header .navbar .navbar-toggler {
    display: flex;
  }
}
@media (max-width: 1199px) {
  .main-header .offcanvas-backdrop {
    background-color: transparent !important;
  }
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body {
    background-color: transparent;
    position: relative;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav {
  flex-grow: 1;
  --bs-link-color: #000;
  --bs-link-hover-color: #08A698;
  padding: 22px 149px 37px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
}
@media (max-width: 1600px) {
  .main-header .offcanvas .offcanvas-body .main-nav {
    grid-template-columns: auto 1fr auto;
  }
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    background-color: transparent;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav .main-logo {
  align-self: center;
}
.main-header .offcanvas .offcanvas-body .main-nav .language-dropdown img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
}
.main-header .offcanvas .offcanvas-body .main-nav .navbar-nav {
  --bs-navbar-color: #000;
  --bs-navbar-hover-color: #08A698;
  --bs-navbar-active-color: #08A698;
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.8);
  align-self: center;
  justify-self: center;
  --bs-nav-link-font-size: 18px;
  --bs-nav-link-font-weight: 400;
  font-family: "Sora";
  gap: 40px;
}
@media (max-width: 1600px) {
  .main-header .offcanvas .offcanvas-body .main-nav .navbar-nav {
    --bs-nav-link-font-size: 16px;
    gap: 30px;
  }
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav .navbar-nav {
    align-self: stretch;
    gap: 0;
    position: relative;
    flex-grow: 1;
  }
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav .navbar-nav .nav-item {
    position: static;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .navbar-nav .nav-item:nth-of-type(1n + 2) {
    border-top: 1px solid #1d1e23;
    margin-top: 12px;
    padding-top: 12px;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav .navbar-nav .nav-item .nav-link > img,
.main-header .offcanvas .offcanvas-body .main-nav .navbar-nav .nav-item .nav-link > svg {
  vertical-align: middle;
  display: inline-block;
  margin-right: 12px;
}
.main-header .offcanvas .offcanvas-body .main-nav .navbar-nav .nav-item .nav-link > span {
  vertical-align: middle;
  display: inline-block;
}
.main-header .offcanvas .offcanvas-body .main-nav .nav.align-items-center {
  justify-content: flex-end;
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav .nav.align-items-center {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .nav.align-items-center .nav-item:nth-of-type(1) {
    margin-top: 40px;
    width: 100%;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .nav.align-items-center .nav-item:nth-of-type(1n + 2) {
    margin-top: 20px;
    width: 100%;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .nav.align-items-center .nav-item .nav-link {
    display: flex;
    width: 100%;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    border-radius: 64px;
    background: #1f2126;
    font-family: "Chillax", sans-serif;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .nav.align-items-center .dropdown.language-dropdown {
    width: 100%;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .nav.align-items-center .dropdown.language-dropdown .nav-link.dropdown-toggle {
    border-radius: 12px;
    border: 1px solid #1f2126;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-toggle[data-bs-toggle=close] {
    border-radius: 12px;
    background: #1f2126;
    padding: 20px 0px 20px 12px;
    width: 100%;
    margin-bottom: 10px;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-toggle[data-bs-toggle=close]:after {
    transform: rotate(90deg);
    order: -1;
    margin-left: 0;
    margin-right: 10px;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-toggle:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M4 6.5L8 10.5L12 6.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  border: 0;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-toggle:after {
    transform: rotate(-90deg);
    margin-left: auto;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu {
  --bs-dropdown-bg: #1f2126;
  --bs-dropdown-border-color: #393b44;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-link-hover-bg: #000;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-border-radius: 12px;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.5);
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: translate(100%);
    visibility: hidden;
    display: block;
    transition: transform 0.2s ease, visibility 0.2s ease;
    background-color: #141518;
  }
  .main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu.show {
    transform: translate(0);
    visibility: visible;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu li {
  padding: 0;
}
.main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu li:nth-of-type(1n + 2) {
  border-top: 1px solid var(--bs-dropdown-border-color);
}
.main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu li a {
  padding: 12px 20px;
  color: #fff;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu li a {
    padding: 12px 0;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav .dropdown .dropdown-menu li a > small {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.14px;
}
.main-header .offcanvas .offcanvas-body .main-nav .btn {
  border-radius: 64px;
  display: inline-flex;
  padding: 12px 52px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Sora", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .main-header .offcanvas .offcanvas-body .main-nav .btn {
    width: 100%;
  }
}
.main-header .offcanvas .offcanvas-body .main-nav .btn:hover, .main-header .offcanvas .offcanvas-body .main-nav .btn:focus, .main-header .offcanvas .offcanvas-body .main-nav .btn:focus-within {
  background: #ad9e40;
}

.support-Dropdwn {
  --postion-offset: 30px;
  position: fixed;
  inset: auto var(--postion-offset) var(--postion-offset) auto;
}
.support-Dropdwn .dropdown .btn {
  letter-spacing: -0.2px;
  --bs-btn-padding-x: 24px;
  --bs-btn-padding-y: 12px;
  --bs-btn-font-family: "Chillax";
  --bs-btn-font-size: 20px;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: normal;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: #f4a4ec;
  --bs-btn-color: #121316;
  --bs-btn-hover-bg: #d067c6;
  --bs-btn-active-bg: #d067c6;
  --bs-btn-hover-color: #121316;
  --bs-btn-active-color: #121316;
  --bs-btn-border-radius: 32px;
  --bs-btn-border-color: var(--bs-btn-bg);
  --bs-btn-hover-border-color: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-bg);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ec407a;
  --bs-btn-disabled-border-color: #ec407a;
}
.support-Dropdwn .dropdown .btn:after {
  border: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6L8 10L4 6' stroke='%23121316' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: baseline;
}
.support-Dropdwn .dropdown .btn.show::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4 10L8 6L12 10' stroke='%23121316' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.support-Dropdwn .dropdown ul.dropdown-menu {
  bottom: 30px !important;
  border-radius: 20px;
  background: #1f2126;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 24px;
}
.support-Dropdwn .dropdown ul.dropdown-menu .dropdown-item {
  color: #fff;
}
.support-Dropdwn .dropdown ul.dropdown-menu .dropdown-item:hover, .support-Dropdwn .dropdown ul.dropdown-menu .dropdown-item:focus {
  color: #fff;
  background: #1f2126;
  opacity: 0.9;
}

.container-narrow .container {
  max-width: 808px;
}

.breadcrumb-section {
  margin: 40px 0;
}
@media (max-width: 767px) {
  .breadcrumb-section {
    margin: 32px 0;
  }
}
.breadcrumb-section .breadcrumb {
  margin: 0;
  --color: #f4a4ec;
  --bs-breadcrumb-divider-color: var(--color);
}
.breadcrumb-section .breadcrumb .breadcrumb-item {
  color: var(--color);
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.breadcrumb-section .breadcrumb .breadcrumb-item + li::before {
  font-size: 22px;
}
.breadcrumb-section .breadcrumb .breadcrumb-item a {
  color: var(--color);
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  text-decoration: none;
}

.rte .accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-active-color: #fff;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-bg: transparent;
  --bs-accordion-border-radius: 0;
  --bs-accordion-border-color: #2d3038;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Ccircle cx='16' cy='16' r='16' fill='%231F2126'/%3E%3Cpath d='M16 8V24' stroke='%23F4A4EC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 16H24' stroke='%23F4A4EC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23F4A4EC'/%3E%3Cpath d='M8 16H24' stroke='%231F2126' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-btn-icon-width: 32px;
  border: 0;
}
.rte .accordion .accordion-item {
  border-width: 0 0 1px;
}
.rte .accordion .accordion-item .accordion-button {
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  box-shadow: none;
}
.rte .accordion .accordion-item .accordion-body {
  color: #fff;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
  letter-spacing: 0.36px;
}
@media (max-width: 767px) {
  .rte .accordion .accordion-item .accordion-body {
    font-size: 16px;
  }
}